/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useContext, useEffect } from "react"

import AuthContext from '../context/AuthProvider';

import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/organisms/Seo/Seo"
import SignupForm from "../components/organisms/SignupForm/SignupForm"
import Container from "../components/atoms/Container/Container"
import PageBanner from "../components/atoms/PageBanner/PageBanner"
import IndividualContentBlock from "../components/atoms/IndividualContentBlock/IndividualContentBlock"

// import styles from "./signup.module.css"

const SignupPage = () => {
    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    

    useEffect(() => {
        if (isLoggedIn && typeof window !== 'undefined') {
            window.location = '/account';
        }
    }, [isLoggedIn])

    if (!isLoggedIn && userChecked) {
        return (
        <Layout>
            <SEO title="Sign up" />
            <PageBanner breadcrumb="Create an account" title="Create an account" color="latte" imageSize="medium">
                <IndividualContentBlock sectionKey="Signup page content" />
            </PageBanner>
            <Container size="large">
                <SignupForm />
            </Container>
        </Layout>
        )
    } else {
        return null;
    }
  }
  
  export default SignupPage
  