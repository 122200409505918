import React, { useState, useContext, useEffect } from 'react';

import AuthContext from '../../../context/AuthProvider';

import Button from '../../atoms/Button/Button';
import Dialog from '../../atoms/Dialog/Dialog';
import { getStorage } from '../../../helpers/general';
import { subscribeForm } from '../../../helpers/klaviyo';

import styles from './SignupForm.module.css';

const SignupForm = () => {
  const auth = useContext(AuthContext);
  const handleRegistration = auth && auth.signup;
  const userEmail = getStorage('_customEmail');
  const [fields, setFields] = useState({});
  const [init, setInit] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);

  useEffect(() => {
    if (!init && userEmail) {
      setFields({ ...fields, ...{ email: userEmail } });
    }
    setInit(true);
  }, [userEmail, fields, init]);

  const attemptRegistration = e => {
    e.preventDefault();

    handleRegistration(fields)
      .then(response => {
        if (fields.email_subscription) {
          const listId = 'TGBFYb';
          const klvFields = {
            email: fields.email,
            first_name: fields.firstName,
            last_name: fields.lastName
          };

          subscribeForm(listId, fields)
            .then(response => {
              if (response && response.status === 200) {
                setDialogMessage(
                  "You've successfully registered! Please check your email to confirm your account."
                );
              } else {
                console.error('Subscription failed with response:', response);
                setDialogMessage(
                  'There was an error subscribing you to our newsletter. Please try again later or contact us for help.'
                );
              }
            })
            .catch(error => {
              console.error('Error during subscription:', error);
            });
        }

        // console.log(response)
      })
      .catch(error => {
        if (typeof error === 'object') {
          console.log(error);
        } else {
          setDialogMessage(error);
        }
      });
  };

  const clearDialog = () => {
    setDialogMessage(false);
  };

  return (
    <form onSubmit={e => attemptRegistration(e)}>
      <div className={`${styles.mobileGrid}`}>
        <div className={`formField ${styles.mobileFormField}`}>
          <label htmlFor="first_name" className={styles.formLabel}>
            First Name
          </label>
          <input
            className={styles.formInput}
            type="text"
            name="first_name"
            onChange={e => setFields({ ...fields, first_name: e.target.value })}
          />
        </div>
        <div className={`formField ${styles.mobileFormField}`}>
          <label htmlFor="last_name" className={styles.formLabel}>
            Last Name
          </label>
          <input
            className={styles.formInput}
            type="text"
            name="last_name"
            onChange={e => setFields({ ...fields, last_name: e.target.value })}
          />
        </div>
        <div className={`formField ${styles.mobileFormField}`}>
          <label htmlFor="email" className={styles.formLabel}>
            Email Address
          </label>
          <input
            className={styles.formInput}
            type="email"
            placeholder={userEmail}
            name="email"
            onChange={e => setFields({ ...fields, email: e.target.value })}
          />
        </div>
        <div className={`formField ${styles.mobileFormField}`}>
          <label htmlFor="password" className={styles.formLabel}>
            Password
          </label>
          <input
            className={styles.formInput}
            type="password"
            name="password"
            onChange={e =>
              setFields({
                ...fields,
                authentication: { new_password: e.target.value }
              })
            }
          />
        </div>
        <div className={`formField ${styles.mobileFormField}`}>
          <input
            className={styles.formInput}
            type="checkbox"
            id="email_subscription"
            name="email_subscription"
            onChange={e =>
              setFields({ ...fields, email_subscription: e.target.checked })
            }
          />
          <label htmlFor="email_subscription" className={styles.formLabel}>
            Subscribe to Newsletter
          </label>
        </div>
      </div>

      <div className="formField">
        <Button
          className={styles.formSubmit}
          level="primary"
          type="buttonSubmit"
        >
          Create Account
        </Button>
      </div>
      <Dialog
        open={dialogMessage ? true : false}
        title="Account creation unsuccessful"
        size="sm"
        hideBtnCancel
        disableBackdropClick
        onOk={() => clearDialog()}
        htmlInString={true}
      >
        {dialogMessage}
      </Dialog>
    </form>
  );
};

export default SignupForm;
